<template>
  <div class="login-container kyc-container">
    <div :class="`container-login100 ${!isMobile ? 'bg-slide' : ''}`">
      <span class="mask bg-gradient-primary opacity-6"></span>
      <div class="wrap-login100">
        <div class="login100-pic">
          <img :src="'/img/theme/kyc-wallet.png'" alt="IMG" />
        </div>

        <el-form>
          <span class="login100-form-title"> KYC</span>
          <el-form-item prop="email" label="">
            <span class="text-white text-message text-kyc"
              >Status: <el-tag type="warning">{{ kycStatus }}</el-tag>  <br />
              Please finish your KYC on AB Money Application</span
            >
          </el-form-item>
          <a href="http://app.ab-fx.com">
            <img
              :src="'/img/theme/app-qr.png'"
              style="width: 100%"
              title="qrcode"
              alt="img"
          /></a>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/helpers";
import WalletAccountApi from "@/api/wallet/account";
import PlusAccountApi from "@/api/account";
export default {
  computed: {
    isMobile() {
      return isMobile();
    },
    userType() {
      return this.$store.getters.usertype;
    },
  },
  data() {
    return { kycStatus: "" };
  },
  methods: {
    getKyc() {
      if (this.userType === "walletuser") {
        WalletAccountApi.getKycState().then(({ result, data }) => {
          if (result) {
            this.kycStatus = data["status"];
            if (
              ["approve", "disapprove", "pending", "finish"].includes(
                this.kycStatus
              )
            ) {
              this.$store.dispatch("user/setKycVerified", true).then(() => {
                this.$router.push({ path: "/wallet/dashboard" });
              });
            }
          }
        });
      } else {
        PlusAccountApi.getKycState().then(({ result, data }) => {
          if (result) {
            this.kycStatus = data["status"];
            if (
              ["approve"].includes(
                this.kycStatus
              )
            ) {
              this.$store.dispatch("user/setKycVerified", true).then(() => {
                this.$router.push({ path: "/dashboard" });
              });
            }
          }
        });
      }
    },
  },
  mounted() {
    this.getKyc();
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/login.scss";

.kyc-container {
  .el-form {
    width: 300px;
    padding: 50px 34px 50px 0;
  }
  .el-form-item__content {
    line-height: 1.5;
  }
  .text-message {
    line-height: 1.5 !important;
  }
}
</style>
